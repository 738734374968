<template>
	<div class="special_asset">
		<el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
  			<el-breadcrumb-item >数字通证</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="box">
			<div class="earnings">
				<div class="top">
					<div class="content">
						<span class="title">我的通证</span>
						<!-- <div style="display: inline-block;float: right;position: absolute;right: 20px;bottom: 6px;">
							<el-button v-if="judge_withdrawal" type="primary" class="real-name" @click="centerDialogVisible = true" style="display: none;!important" disabled>批量提现</el-button>
							<el-button v-if="judge_realname" type="primary" class="real-name" @click="goAuthentication()"  >请实名认证</el-button>
							<el-button v-if="judge_wallet" type="primary" class="real-name validation" @click="centerDialogVisible1 = true">钱包与手机验证</el-button>
						</div> -->
					</div>
				</div>
				<div class="bottom">
					<div class="earnings-details">
						<div class="total">
							<p class="total-number">总奖励</p>
							<p class="number">0</p>
						</div>
						<div class="daily-earnings">
							<p class="hpt">可提现</p>
							<p class="number">0</p>
							<!-- <p class="number" style="padding-top: 4px;">0</p> -->
							<!-- <p class="total_cash">(总可提现数量:0)</p> -->
						</div>
						<div class="total-earnings">
							<p class="hpt">冻结中</p>
							<p class="number">0</p>
						</div>
					</div>
				</div>
			</div>
			<div class="prompt">
				<div class="top">
					<div class="content">
						<span class="title">温馨提示</span>
					</div>
				</div>
				<div class="bottom">
					<p>1、批量提取时只能提取每个数据中心的整数部分;</p>
					<p>2、最低提取数量要大于1才能提取;</p>
					<p>3、强烈建议，每次提取数量最好累加到10个左右再统一提取，以减少损耗;</p>
					<p>4、请完成实名认证、手机安全验证后再进行提现操作;</p>
				</div>
			</div>
		</div>
        <!-- <div class="mine-field">
            <div class="top">
				<div class="tit">
					<span class="title">数据中心：{{mineform.length}}</span>
				</div>
			</div>
			<div class="mine-list">
				<div class="item" v-for="item in mineform" @click="goParam(item)">
					
					<p class="mine-name">
						<span class="name">{{item.pool_name}}</span>
						<span class="enter"><i class="el-icon-arrow-right"></i></span>
					</p>
					<div class="mine-detail">
						<div class="left">
							<p class="text">可提现数量</p>
							<p class="number">{{item.cash}}</p>
						</div>
						<div class="right">
							<p class="text">锁仓数量</p>
							<p class="number">{{item.locked}}</p>
						</div>
						<div class="installment" v-if="item.stage == true">
							<div class="free_install">
								<p class="text">
									<span>自由分期</span>
								</p>
								<p class="number">{{item.stage_cash}}</p>
							</div>
							<div class="locked_install">
								<p class="text">
									<span>锁仓分期</span>
								</p>
								<p class="number">{{item.stage_locked}}</p>
								<div class="countdown" style="display: none;" >倒计时:{{item.lose_release_days}}天</div>
							</div>
						</div>
						<div class="right" v-if="item.release_180>0" style="border: 0;">
							<p class="text">180天锁仓数量</p>
							<p class="number">{{item.release_180}}</p>
						</div>
					</div>
					
				</div>
			</div>
        </div> -->
		<!-- <el-form ref="form" :model="form" label-width="120px">
			<el-dialog title="申请提现" :visible.sync="centerDialogVisible" width="30%" center>
  			<div class="popup">
				  <div class="top">
					  <div class="left">
						  <p class="text">实际可提现总数</p>
						  <p class="price">{{Formdata.data.cash_int}}</p>
					  </div>
					  <div class="right">
						  <p class="text">输入提现数量</p>
						  <el-form-item prop="nums">
							  <el-input v-model="form.nums" placeholder="请输入" class="popup-input" onKeypress="return(/^[1-9]*[0-9]*$/.test(String.fromCharCode(event.keyCode)))" type="number"></el-input>
						  </el-form-item>
					  </div>
				  </div>
				  <div class="bottom">
						<div style="display:flex;line-height:30px;margin: 20px 20px 0;">
						</div>
						<div style="display:flex;line-height:30px;margin: 0 20px;">
							<label style="width:57px;padding-left:18px;">提现到:</label>
							<div>{{infoform.wallet}}</div>
						</div>
				  </div>
			</div>
  			<span slot="footer" class="dialog-footer">
    			<el-button @click="centerDialogVisible = false">取 消</el-button>
    			<el-button type="primary" @click="centerDialogVisible = false,handleMultiple(form.nums)">申请提现</el-button>
  			</span>
		</el-dialog>
		</el-form> -->
		<!-- <el-dialog title="安全验证" :visible.sync="centerDialogVisible1" width="30%" center>
		    <el-form :model="submitForm" :rules="rules" ref="submitForm" label-width="150px" class="demo-ruleForm authentication-form">
		            <el-form-item label="钱包地址" prop="wallet" placeholder="请填写地址" style="width:80%">
		                <el-input v-model="submitForm.wallet"></el-input>
		            </el-form-item>
		            <el-form-item label="手机号码" prop="mobile" placeholder="请输入手机号码" style="width:80%">
		                <el-input v-model="submitForm.mobile"></el-input>
		            </el-form-item>
		            <el-form-item label="手机验证码" prop="code" placeholder="请输入验证码" style="width:60%;position:relative;">
		                <el-input v-model="submitForm.code"></el-input>
		                <el-button type="primary" v-if="showbtn" style="position:absolute;right:-120px;width:112px" @click="handleSend(submitForm.mobile)">获取验证码</el-button>
						<el-button type="primary" v-else style="position:absolute;right:-120px;width:112px":disabled="attcode">{{code_ts}}</el-button>
		            </el-form-item>
		        </el-form>
		    <span slot="footer" class="dialog-footer">
		        <el-button @click="centerDialogVisible1 = false">取 消</el-button>
		        <el-button type="primary" @click="centerDialogVisible1 = false,handleSafe(submitForm.mobile,submitForm.wallet,submitForm.code)">确 定</el-button>
		    </span>
		</el-dialog> -->
	</div>	
</template>

<script>
	import { Property } from "@/api/index";
	import { Personal } from "@/api/index";
	export default {
    data() {
      return {
		// confirm: true,  //提交验证按钮判断
		// attcode: false,  //点击获取验证码按钮判断
		// showbtn: true, // 展示获取验证码或倒计时按钮判断
		// yzcode: '',
		// code_ts: '获取验证码', //倒计时提示文字
		// sec: 60 ,// 倒计时秒数
		// centerDialogVisible: false,
		// centerDialogVisible1: false,
		// walletform:{},
		// submitForm: {
		// 	wallet:'',
		// 	mobile:'',
		// 	code:'',
		// },
		// rules: {
		//   wallet: [
		//     { required: true, message: '请填写地址', trigger: 'blur' },
		//   ],
		//   mobile: [
		//     { required: true, message: '请输入手机号', trigger: 'blur' },
		//   ],
		//   code: [
		//     { required: true, message: '请输入验证码', trigger: 'blur' },
		//   ],
		// },
		// form:{
		// 	nums:'',
		// },
		// Formdata:{data:{}},
		// infoform:[],
		// mineform:[],
		// judge_withdrawal:false,
		// judge_realname:false,
		// judge_wallet:false,
      };
    },
	created() {
		// this.Cash()
		// this.Pools()
		},
	methods: {
	// 	goAuthentication() {
	// 		this.$router.push('/personal/authentication')
	// 	},
	// 	async Cash() {
	// 			let { status, data } = await Property.cash();
	// 			if (status) {
	// 				this.Formdata = data;
	// 				this.Ifinfo()
	// 			}
	// 		},
	// 	async Pools() {
	// 			let { status, data } = await Property.pools(); 
	// 			if (status) {
	// 				this.mineform = data.data;
	// 			}
	// 		},
	// 		goParam(item){
	// 			this.$router.push({path:'mine?pool_id='+item.pool_id,params:{pool_id:item.pool_id}})
	// 		},
	// 	//判断信息
	// 	async Ifinfo() {
	// 			let { status, data } = await Personal.info();
	// 			if (status) {
	// 					this.infoform = data.data;
	// 					//提现判断
	// 					this.judge_withdrawal = this.infoform.status==5 && this.Formdata.data.cash_int > 0 && this.infoform.mobile_status == 1;
	// 					//实名判断
	// 					this.judge_realname = this.infoform.status != 5;
	// 					//钱包判断
	// 					this.judge_wallet = (this.infoform.wallet == '' ||this.infoform.wallet == null) && this.infoform.mobile_status == 0;
	// 			}
	// 		},
	// 	//批量提现
	// 	async handleMultiple(nums) {
	// 				let { status, data} = await Property.multiple({nums:nums},this.form);
	// 				if(status) {
	// 					if(data.code == 200){
	// 						this.$message.success(data.data);
	// 						this.Cash()
	// 						this.Pools()
	// 						this.Ifinfo()
	// 					}else{
	// 						this.$message.error(data.message);
	// 					}
	// 				}
	// 	},
	// 	//发送短信
	// 	async handleSend(mobile) {
	// 		let { status, data } = await Personal.sendsms(this.submitForm);
	// 		if(status) {
	// 			if (data.code == 200) {
	// 				var timer = setInterval(() => {
	// 				        this.sec = this.sec-1
	// 				        this.code_ts = this.sec + 'S后重试'
	// 				        this.showbtn = false
	// 						this.attcode = true
	// 				        if (this.sec === 0) {
	// 				            clearInterval(timer)
	// 				            this.sec = 60
	// 				            this.code_ts = this.sec + 'S后重试'
	// 				            this.showbtn = true
	// 							this.attcode = false
	// 				        }
	// 				    }, 1000)
	// 					this.$message.success(data.data);
	// 			}else {
	// 			this.$message.error(data.message);
	// 		}
	// 		}
	// 	},
	// 	//手机安全+钱包地址
	// 	handleSafe(wallet,mobile,code) {
	// 		this.$refs.submitForm.validate(async (valid) => {
	// 			if (valid) {
	// 				let { status, data } = await Personal.safe(this.submitForm);
	// 				if (status) {
	// 						if(data.code==200) {
	// 							this.$message.success(data.data);
	// 							this.Ifinfo()
	// 						}else {
	// 							this.$message.error(data.message);
	// 						}
	// 					}
	// 				} 
	// 		})
	// },
	// goBuy(){
	// 	this.$router.push('/buy')
	//  },
	}
	}
</script>

<style lang="less">
	body {
		margin: 0;
		padding: 0;
		background-color: #F0F3FA;
	}
	.special_asset {
		.el-form-item__content {
			margin-left: 0!important;
			display: flex;
			justify-content: center;
		}
		.el-form-item__error {
			margin-left: 30px;
		}
		.el-breadcrumb{
			padding: 30px;
			.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}
			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}
		.box {
			display: flex;
			margin: 0 20px;
			.earnings {
				width: 70%;
				height: 320px;
				margin-right: 20px;
				.top {
					height: 58px;
					background-color: #F8F8F8;
					.content {
		                position: relative;
		                padding: 15px 20px;
						.title {
							font-size: 20px;
							color: #333333;
							font-weight: 600;
							border-left: 3px solid #1493FA;
							padding-left: 10px;
						}
						i {
							color: #999999;
							margin: 0 15px;
						}
						.tyep {
							font-size: 18px;
							color: #333333;
						}
		
					}
				}
				.bottom {
					height: 262px;
					background-color: #ffffff;
					.earnings-details {
						display: flex;
						padding-top: 45px;
						.total {
							width: 33%;
							height: 90px;
							border-right: 1px dashed #BBBBBB;
							.total-number {
		                        text-align: center;
		                        padding-left: 0;
								font-size: 18px;
								color: #333333;
								font-weight: 600;
							}
							.number {
								text-align: center;
								padding-top: 25px;
								font-size: 34px;
								color: #FF5722;
								font-weight: 600;
							}
						}
						.daily-earnings {
							width: 33%;
							height: 90px;
							border-right: 1px dashed #BBBBBB;
							.hpt {
								text-align: center;
								font-size: 18px;
								color: #333333;
								font-weight: 600;
							}
							.total_cash {
								text-align: center;
								color: #999999;
								font-size: 14px;
							}
							.number {
								text-align: center;
								padding-top: 25px;
								font-size: 34px;
								color: #FF5722;
								font-weight: 600;
							}
						}
						.total-earnings {
							width: 33%;
							height: 90px;
							.hpt {
								text-align: center;
								font-size: 18px;
								color: #333333;
								font-weight: 600;
							}
							.number {
								text-align: center;
								padding-top: 25px;
								font-size: 34px;
								color: #FF5722;
								font-weight: 600;
							}
						}
					}
					.el-button--primary {
						margin-left: 30px;
						margin-top: 45px;
					}
				}
			}
			.prompt {
				width: calc(30% - 20px);
				height: 320px;
				.top {
					height: 58px;
					background-color: #F8F8F8;
					.content {
						padding: 15px 20px;
						.title {
							font-size: 20px;
							color: #333333;
							font-weight: 600;
							border-left: 3px solid #1493FA;
							padding-left: 10px;
						}
					}
				}
				.bottom {
					height: 262px;
					background-color: #ffffff;
					padding: 30px;
					box-sizing: border-box;
					p {
						line-height: 30px;
						font-size: 18px;
						color: #333333;
					}
				}
			}
		}
		.mine-field {
			margin: 20px;
			background-color: #fff;
		    .top {
					height: 58px;
					background-color: #F8F8F8;
					.tit {
						padding: 15px 20px;
						.title {
							font-size: 20px;
							color: #333333;
							font-weight: 600;
							border-left: 3px solid #1493FA;
							padding-left: 10px;
						}
					}
				}
			.mine-list {
				padding: 0 20px 20px 0px;
				display: flex;
				flex-wrap: wrap;
				.item {
					background-color: #F9FAFC;
					height: 202px;
					width: 480px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					margin-top: 30px;
					margin-left: 30px;
					cursor: pointer;
					.mine-name {
						display: flex;
						justify-content: space-between;
						padding: 30px;
						.name {
							font-size: 26px;
							color: #0486FE;
						}
						.enter {
							font-size: 26px;
							color: #0486FE;
							float: right;
						}
					}
					.mine-detail {
						display: flex;
						.left {
							width: 25%;
							border-right: 1px dashed #cccccc;
							text-align: center;
							.text {
								font-size: 16px;
								color: #333333;
								padding-bottom: 5px;
							}
							.number {
								font-size: 16px;
								color: #333333;
								padding-bottom: 5px;
							}
						}
						.right {
							width: 25%;
							border-right: 1px dashed #cccccc;
							text-align: center;
							.text {
								font-size: 16px;
								color: #333333;
								padding-bottom: 5px;
							}
							.number {
								font-size: 16px;
								color: #333333;
								padding-bottom: 5px;
							}
						}
						.installment {
							width: 50%;
							display: flex;
							text-align: center;
							.free_install {
								width: 50%;
								border-right: 1px dashed #cccccc;
								text-align: center;
								.text {
									font-size: 16px;
									color: #333333;
									padding-bottom: 5px;
									span {
										vertical-align: middle;
									}
								}
								.number {
									font-size: 16px;
									color: #333333;
									padding-bottom: 5px;
								}
							}
							.locked_install {
								width: 50%;
								text-align: center;
								position: relative;
								.countdown {
									position: absolute;
									bottom: -20px;
									width: 100%;
									text-align: center;
									color: #333333;
									font-size: 14px;
								}
								.text {
									font-size: 16px;
									color: #333333;
									padding-bottom: 5px;
									span {
										vertical-align: middle;
									}
									
								}
								.number {
									font-size: 16px;
									color: #333333;
									padding-bottom: 5px;
								}
							}
						}
					}
				}
				.buy {
					background-color: #F9FAFC;
					height: 202px;
					width: 480px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					margin-top: 30px;
					margin-left: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					.buy-server {
						font-size: 26px;
						color: #0486FE;
					}
				}
			}
		}
		.popup {
			.top {
				display: flex;
				height: 120px;
				border-bottom: 1px solid #E9E9E9;
				.left {
					width: 50%;
					text-align: center;
					border-right: 1px solid #E9E9E9;
					height: 86px;
					.text {
						font-size: 18px;
						color: #333333;
						padding-bottom: 20px;
					}
					.price {
						font-size: 30px;
						color: #0486FE;
					}
				}
				.right {
					width: 50%;
					text-align: center;
					height: 86px;
					.text {
						font-size: 18px;
						color: #333333;
						padding-bottom: 20px;
					}
					.popup-input {
						width: 200px;
					}
				}
			}
		}
	}
	
</style>